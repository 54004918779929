import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/maintenance";

// components
import Layout from "../components/layout";
import TitleSeparator from "../components/titleSeparator";

class maintenancePage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="meta" whiteMenu={true}>
          <div className="pt-menu">
            <div className="paddingSidesBig pt-150 pb-150 text-center">
              <TitleSeparator info={this.text.maintenance}></TitleSeparator>
              <img src={this.text.loading.src} alt={this.text.loading.alt}></img>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(maintenancePage);
