// icons
import LoadingIcon from "../images/icon/loadingIcon.svg";

export const pageTexts = (t) => {
  return {
    maintenance: {
      title: {
        text: t("maintenance.workingImprove"),
        colorClass: "colorDark",
        extraClass: "font34 m-0",
        heading: "h3",
      },
      text: {
        text: t("maintenance.backSoon"),
        colorClass: "colorDark",
        extraClass: "font26",
        heading: "h4",
      },
      separator: true,
    },
    loading: {
        src: LoadingIcon,
        alt: t("maintenance.loadingAlt")
    }
  };
};
